import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { ValidationErrorItem } from '@hapi/joi'

import { Button, Input } from 'components'
import { useUserInfo } from 'shared/hooks/userInfo/useUserInfo'
import { ReactComponent as InvalidRuleIcon } from 'assets/svg/passwordManagementScreenInvalidRuleIcon.svg'
import { ReactComponent as ValidRuleIcon } from 'assets/svg/passwordManagementScreenValidRuleIcon.svg'
import { StringSchemeErrorsEnum } from 'utilities/validation'
import { ChangePasswordRequest } from 'services/auth'
import { useToast } from 'shared/hooks'

import {
  PasswordRules,
  changePasswordSchema,
  PasswordSchema,
  newPasswordSchema,
} from './changePasswordSchema'
import styles from './ChangePassword.module.scss'
import { paths } from 'routes'

interface PasswordRuleProps {
  label: string
  hasError: boolean
}

const PasswordRule = ({ label, hasError }: PasswordRuleProps) => {
  return (
    <div className={styles.rule}>
      {hasError ? <InvalidRuleIcon /> : <ValidRuleIcon />}
      <p className={styles.ruleLabel}>{label}</p>
    </div>
  )
}

const ChangePasssword = () => {
  const navigate = useNavigate()
  const { updateUserPassword } = useUserInfo()
  const { addToast } = useToast()
  const [newPasswordErrors, setNewPasswordErrors] = useState<
    ValidationErrorItem[]
  >([])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<PasswordSchema>({
    resolver: joiResolver(changePasswordSchema),
    mode: 'all',
  })

  const newPassword = watch('newPassword')
  useEffect(() => {
    const result = newPasswordSchema.validate(newPassword, {
      abortEarly: false,
    })

    setNewPasswordErrors(result.error?.details || [])
  }, [newPassword])

  const hasPasswordError = useCallback(
    (rule: string) =>
      !!newPasswordErrors.find(
        (error) =>
          error.type === StringSchemeErrorsEnum.EMPTY ||
          error.type === rule ||
          error.message.includes(rule),
      ),
    [newPasswordErrors],
  )

  const handleUpdatePassword = useCallback(
    async (data: ChangePasswordRequest) => {
      try {
        await updateUserPassword(data)
        addToast({ message: 'Senha redefinida com sucesso.' })
        navigate(paths.profile.info)
      } catch {
        addToast({
          message: 'Erro ao redefinir a senha. Tente novamente.',
          type: 'alert',
        })
      }
    },
    [updateUserPassword, addToast, navigate],
  )

  return (
    <div className={styles.password}>
      <div className={styles.passwordHeader}>
        <p>Redefinição de senha</p>
      </div>

      <div className={styles.passwordBody}>
        <div className={styles.inputWrapper}>
          <Input
            {...register('currentPassword')}
            label="Digite sua senha atual"
            placeholder="Senha atual"
            errorMessage={errors.currentPassword?.message}
            password
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            {...register('newPassword')}
            label="Digite sua nova senha"
            placeholder="Nova senha"
            password
          />
        </div>
        <div className={[styles.inputWrapper, styles.lastInput].join(' ')}>
          <Input
            {...register('confirmNewPassword')}
            name="confirmNewPassword"
            label="Confirme sua nova senha"
            placeholder="Nova senha"
            errorMessage={errors.confirmNewPassword?.message}
            password
          />
        </div>

        <h5 className={styles.passwordRules}>SUA SENHA DEVE TER:</h5>
        <PasswordRule
          label="Mínimo de 8 caracteres"
          hasError={hasPasswordError(StringSchemeErrorsEnum.MIN)}
        />
        <PasswordRule
          label="Mínimo 1 número"
          hasError={hasPasswordError(PasswordRules.atLeastOneNumber)}
        />
        <PasswordRule
          label="Mínimo 1 letra maiúscula"
          hasError={hasPasswordError(PasswordRules.atLeastOneUpperCase)}
        />
        <PasswordRule
          label="Mínimo 1 letra minúscula"
          hasError={hasPasswordError(PasswordRules.atLeastOneLowerCase)}
        />
        <PasswordRule
          label="Mínimo 1 caractere especial (@*!%:.)"
          hasError={hasPasswordError(PasswordRules.atLeastOneSpecialCharacter)}
        />
      </div>

      <div className={styles.passwordFooter}>
        <Button
          buttonTitle="Voltar"
          onClick={() => navigate(paths.profile.info)}
          type="secondary"
          width="172px"
        />
        <Button
          buttonTitle="Salvar"
          disabled={!isValid}
          onClick={handleSubmit(handleUpdatePassword)}
          type="primary"
          width="172px"
        />
      </div>
    </div>
  )
}

export default ChangePasssword
